import React from "react";
import PageOne from "../src/pages/page1/PageOne";

function App() {
  return (
    <div className="App">
      <PageOne />
    </div>
  );
}

export default App;
